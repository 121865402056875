import React, {useEffect, useMemo, useState} from 'react'
import {Button, Card, CardBody, Col, Form, Input, Label, Row} from "reactstrap"
import Select from "react-select"
import {assetFullName, cryptoIconsMapper, isTwoPrecision} from "@metronic/helpers/utils.jsx"
import {selectListData} from "@metronic/api/general.js"
import {Link, useForm, usePage} from "@inertiajs/react"
import {Content} from "@metronic/_metronic/layout/components/Content"
import {Toolbar} from "@metronic/_metronic/layout/components/toolbar/Toolbar"
import {head} from "lodash";

const CreateRequestCard = () => {
  const [assetOptions, setAssetOptions] = useState([])
  const userInfo = usePage().props?.auth?.user

  const [sellAsset, setSellAsset] = useState(null)
  const [buyAsset, setBuyAsset] = useState(null)
  const [sellAmount, setSellAmount] = useState('')
  const [buyAmount, setBuyAmount] = useState('')
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [countriesOptions, setCountriesOptions] = useState([])

  const prepareAssetOptions = (asset) => ({
    value: asset.value,
    label: (
      <div className="text-truncate">
        <img
          src={cryptoIconsMapper[asset.label]}
          alt={asset.label}
          style={{width: '20px', marginRight: '10px'}}
        />
        {assetFullName[asset.label]}
      </div>
    )
  })

  // Generate a list of options with icons for the asset selection field in child components
  const buyOptions = useMemo(
    () => assetOptions.map(prepareAssetOptions),
    [assetOptions, cryptoIconsMapper, assetFullName]
  )

  const sellOptions = useMemo(
    () => assetOptions.reverse().map(prepareAssetOptions),
    [assetOptions, cryptoIconsMapper, assetFullName]
  )

  const {data, setData, post, reset} = useForm({
    sell_amount: '',
    sell_asset: '',
    buy_amount: '',
    buy_asset: '',
    meta: {
      first_name: '',
      last_name: '',
      email: '',
      country: '',
    },
    customer_id: '',
  })

  // Submit request from CreateRequestCard component
  const createRequestHandler = (e) => {
    e.preventDefault()
    const btn = e.target.querySelector('[type="submit"]')
    btn.disabled = true

    post('/p2p-orders/create', {
      preserveScroll: true,
      onSuccess: () => {
        reset()
        clearForm()
      },
      onFinish() {
        btn.disabled = false
      }
    })
  }

  const clearForm = () => {
    setSellAmount('')
    setBuyAmount('')
  }

  const changeBuyAmountHandler = (value) => {
    if (sellAsset && buyAsset && assetOptions) {
      const sellAssetPrice = assetOptions.find(i => i.value === sellAsset.value)?.price
      const buyAssetPrice = assetOptions.find(i => i.value === buyAsset.value)?.price
      const sellAssetCode = assetOptions.find(i => i.value === sellAsset.value)?.label
      setSellAmount(isTwoPrecision(sellAssetCode, (Number(buyAssetPrice) * Number(value)) / Number(sellAssetPrice)))
    }

    setBuyAmount(value)
    setData('buy_amount', value)
  }

  const changeSellAmountHandler = (value) => {
    if (sellAsset && buyAsset && assetOptions) {
      const sellAssetPrice = assetOptions.find(i => i.value === sellAsset.value)?.price
      const buyAssetPrice = assetOptions.find(i => i.value === buyAsset.value)?.price
      const buyAssetCode = assetOptions.find(i => i.value === buyAsset.value)?.label
      setBuyAmount(isTwoPrecision(buyAssetCode, (Number(sellAssetPrice) * Number(value)) / Number(buyAssetPrice)))
    }

    setSellAmount(value)
    setData('sell_amount', value)
  }

  const swapValuesHandler = (buy_amount, new_buy_asset, new_sell_asset) => {
    changeSellAmountHandler(buy_amount)
    setSellAsset(new_sell_asset)
    setBuyAsset(new_buy_asset)
    setData(prevData => ({
      ...prevData,
      sell_asset: new_sell_asset.value,
      buy_asset: new_buy_asset.value
    }))
  }

  useEffect(() => {
    selectListData('assets_list').then(r => setAssetOptions(r))
    selectListData('countries').then(r => setCountriesOptions(r))
  }, [])

  useEffect(() => {
    if (buyAmount) {
      changeBuyAmountHandler(buyAmount)
    }
  }, [buyAsset, sellAsset])

  useEffect(() => {
    if (sellAmount) {
      changeSellAmountHandler(sellAmount)
    }
  }, [sellAsset, buyAsset])

  useEffect(() => {
    if (userInfo && countriesOptions) {
      let userCountry = countriesOptions.find(i => i.value === userInfo.profile.country)

      setSelectedCountry(userCountry)
      setData('meta', {...data.meta, country: userCountry?.label})
    }
  }, [userInfo, countriesOptions])

  useEffect(() => {
    if (sellAmount || buyAmount) {
      setData(prevData => ({
        ...prevData,
        sell_amount: sellAmount,
        buy_amount: buyAmount
      }))
    }
  }, [sellAmount, buyAmount])

  useEffect(() => {
    if (userInfo) {
      setData(prevData => ({
        ...prevData,
        meta: {
          ...prevData.meta,
          email: userInfo.email,
          first_name: userInfo.profile.first_name,
          last_name: userInfo.profile.last_name,
        },
        customer_id: userInfo.id
      }))
    }
  }, [userInfo])

  useEffect(() => {
    if (buyOptions && buyOptions?.length) {
      console.log(head(buyOptions)?.value);

    }
  }, [buyOptions])

  useEffect(() => {
    if (sellOptions && sellOptions?.length) {
      let val = head(sellOptions)?.value

      setSellAsset(head(sellOptions))
      setData(prevData => ({...prevData, sell_asset: val}))
    }
  }, [sellOptions])

  useEffect(() => {
    if (buyOptions && buyOptions?.length) {
      let val = head(buyOptions)?.value

      setBuyAsset(head(buyOptions))
      setData(prevData => ({...prevData, buy_asset: val}))
    }
  }, [buyOptions])

  return (
    <>
      <Toolbar/>
      <Content>
        <Row className="g-5 g-xl-10 mb-5 mb-xl-10">
          <Col className="col-12">
            <Card className="py-10">
              <CardBody>
                <h1 className="w-100 text-center mb-10">Create order</h1>

                <Form className="d-flex flex-column" onSubmit={(e) => createRequestHandler(e)}>
                  <div className="mb-3 d-column d-md-flex">

                    <div className="w-100">
                      <div className="form-group position-relative">
                        <Label className="fw-bold mb-1 ms-1 fs-3 d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            Sell<i className="bi bi-arrow-up-right-square-fill text-danger fw-bold fs-4 ms-3"></i>
                          </div>
                          {sellAsset && assetOptions.length ?
                            <div className="fw-bold fs-7 text-nowrap text-truncate">
                              Rate: <span className="ps-1 text-success">
                              1 {assetOptions.find(i => i.value === sellAsset.value)?.label}
                              <span> / </span>
                              {Number(assetOptions.find(i => i.value === sellAsset.value)?.price).toFixed(2)} USD
                            </span>
                            </div> : null
                          }
                        </Label>
                        <Input
                          className="fw-bold fs-4"
                          style={{padding: "11px 160px 11px 11px"}}
                          type="text"
                          placeholder="0.00"
                          name="sell_amount"
                          autoComplete="off"
                          value={data.sell_amount || ''}
                          onChange={e => changeSellAmountHandler(e.target.value)}
                        />
                        <div className="form-group w-150px position-absolute p2p-select">
                          <Select
                            className="bg-light-primary"
                            options={sellOptions || []}
                            name="sell_asset"
                            value={sellAsset}
                            onChange={(selected) => {
                              setSellAsset(selected)
                              setData('sell_asset', selected.value)
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-center">
                      <i
                        className="bi bi-arrow-left-right fs-3 fw-bold text-success mx-3 d-none mb-5 align-self-end d-md-block cursor-pointer"
                        onClick={() => swapValuesHandler(data.buy_amount, sellAsset, buyAsset)}
                        ></i>
                      <i
                        className="bi bi-arrow-down-up fs-2 fw-bold text-success d-block d-md-none mt-3 align-self-end mx-3 cursor-pointer"
                        onClick={() => swapValuesHandler(data.buy_amount, sellAsset, buyAsset)}
                        ></i>
                    </div>

                    <div className="w-100">
                      <div className="form-group position-relative">
                        <Label className="fw-bold mb-1 ms-1 fs-3 d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            Buy <i className="bi bi-arrow-down-right-square-fill text-success fw-bold fs-4 ms-3"></i>
                          </div>
                          {buyAsset && assetOptions.length ?
                            <div className="fw-bold fs-7 text-nowrap text-truncate">
                              Rate: <span className="ps-1 text-success">
                              1 {assetOptions.find(i => i.value === buyAsset.value)?.label}
                              <span> / </span>
                              {Number(assetOptions.find(i => i.value === buyAsset.value)?.price).toFixed(2)} USD
                            </span>
                            </div> : null
                          }
                        </Label>
                        <Input
                          className="fw-bold fs-4"
                          style={{padding: "11px 160px 11px 11px"}}
                          type="text"
                          placeholder="0.00"
                          name="buy_amount"
                          autoComplete="off"
                          value={data.buy_amount || ''}
                          onChange={e => changeBuyAmountHandler(e.target.value)}
                        />
                        <div className="form-group w-150px position-absolute p2p-select">
                          <Select
                            className="bg-light-primary"
                            options={buyOptions || []}
                            name="buy_asset"
                            value={buyAsset}
                            onChange={(selected) => {
                              setBuyAsset(selected)
                              setData('buy_asset', selected.value)
                            }}
                          />
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="separator my-7 border-primary separator-content text-nowrap text-primary fw-bold">
                    Card information
                  </div>
                  <Row>
                    <Col className="col-12 col-sm-6">
                      <label className="ps-2 fw-bold text-muted">
                        First name<sup className="text-danger fs-12">*</sup>
                      </label>
                      <Input
                        type="text"
                        className="form-control text-gray-700 fw-bold"
                        placeholder="First name"
                        name="first_name"
                        autoComplete='off'
                        value={data.meta.first_name}
                        onChange={e => setData('meta', {...data.meta, first_name: e.target.value})}
                      />
                    </Col>
                    <Col className="col-12 col-sm-6 mt-3 mt-sm-0">
                      <label className="ps-2 fw-bold text-muted">
                        Last name<sup className="text-danger fs-12">*</sup>
                      </label>
                      <Input
                        type="text"
                        className="form-control text-gray-700 fw-bold"
                        placeholder="Last name"
                        name="last_name"
                        autoComplete='off'
                        value={data.meta.last_name}
                        onChange={e => setData('meta', {...data.meta, last_name: e.target.value})}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col className="col-12 col-sm-6">
                      <label className="ps-2 fw-bold text-muted">
                        E-mail<sup className="text-danger fs-12">*</sup>
                      </label>
                      <Input
                        type="email"
                        className="form-control text-gray-700 fw-bold"
                        placeholder="E-mail"
                        name="email"
                        autoComplete='off'
                        value={data.meta.email}
                        onChange={e => setData('meta', {...data.meta, email: e.target.value})}
                      />
                    </Col>
                    <Col className="col-12 col-sm-6 mt-3 mt-sm-0">
                      <label className="ps-2 mb-1 fw-bold text-muted">
                        Country<sup className="text-danger fs-12">*</sup>
                      </label>
                      <Select
                        className="text-gray-700 fw-bold"
                        placeholder="Country"
                        name="country"
                        autoComplete='off'
                        value={selectedCountry}
                        onChange={selected => {
                          setSelectedCountry(selected)
                          setData('meta', {...data.meta, country: selected.label})
                        }}
                        options={countriesOptions || []}
                      />
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-between mt-10">
                    <Link
                      type="button"
                      href={'/p2p-orders/'}
                      className="btn btn-light-warning fw-bold"
                    >
                      Cancel
                    </Link>

                    <Button
                      type="submit"
                      className="btn btn-light-success fw-bold"
                    >
                      Create request
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Content>
    </>
  )
}

export default CreateRequestCard
